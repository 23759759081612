import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

const getintouchCompData = () => {
  const data = useStaticQuery(
    graphql`
      {
        # Section texts, formId
        wp {
          mdaThemeOptions {
            themeSettings {
              getintouch {
                formApiEndpoint
                label
                header
                selectPlaceholder
                textBottom
                formId
                optionalText
                buttonText
                buttonTextSending
                textSuccess
                countries {
                  value
                  label
                }
                businesses {
                  value
                  label
                }

                inputLabels {
                  fieldName
                  label
                  placeholder
                }
              }
            }
          }
        }
      }
    `
  );

  return data;
};

export { getintouchCompData };
