// Default imports
import React from "react";
import { StaticQuery, graphql } from "gatsby";

import { FixSvg } from "../helpers/svgHelpers";

// Wave
const Wave = ({
  variant,
  noAnimation,
  noBlend,
  noStyles,
  className,
  ...args
}) => {
  const randomNumber = Math.floor(Math.random() * (40 - 20 + 1)) + 20;
  return (
    <StaticQuery
      query={graphql`
        {
          allFile {
            edges {
              node {
                name
                childSvg {
                  content {
                    data
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const allWaves = data.allFile.edges.filter((item) =>
          item.node.name.includes("wave")
        );

        if (allWaves.length === 0) {
          console.log(
            `No waves found. Variant: ${variant ? variant : "default"}.`
          );
          return <></>;
        }

        const variantWave = allWaves.find(
          (wave) => wave.node.name === `wave-${variant}`
        );
        const wave = variantWave || allWaves[0];

        return (
          <FixSvg
            svg={wave.node.childSvg.content.data}
            style={{ animationDuration: `${randomNumber}s` }}
            className={`wave ${className ? className : ""} ${
              noAnimation ? "-no-animation" : ""
            } ${noBlend ? "-no-blend" : ""} ${noStyles ? "-no-styles" : ""}`}
            {...args}
          />
        );
      }}
    />
  );
};

export default Wave;
