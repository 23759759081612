import React, { useState } from "react";
import propTypes from "prop-types";

/**
 * Send form to AlgoTrader API
 */
const useFormApiSender = async ({ url, form, onSuccess, onError }) => {
  // Get form data in JSON format
  const formData = new FormData(form);
  const formDataJson = Object.fromEntries(formData.entries());

  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");
  reqHeaders.append("Access-Control-Allow-Origin", "*");

  // Send request
  fetch(url, {
    method: "POST",
    headers: reqHeaders,
    body: JSON.stringify(formDataJson),
    redirect: "follow",
  })
    .then((result) => {
      if (result.status === 200) {
        onSuccess();
      } else {
        onError([{ fieldName: -1, message: result.error }]);
      }
    })
    .catch((error) => {
      onError([{ fieldName: -1, message: error }]);
      console.log("error", error);
    });
};

useFormApiSender.propTypes = {
  form: propTypes.array.isRequired,
  formId: propTypes.number.isRequired,
  onSuccess: propTypes.func,
  onError: propTypes.func,
};

export default useFormApiSender;
