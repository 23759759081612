// Default imports
import React from "react";

const Section = ({ children, className }) => (
  <section className={`section ${className ? className : ""}`}>
    {children}
  </section>
);

export default Section;
