// Default imports
import React from "react";

const SectionHeader = ({
  labelTop,
  labelBottom,
  header,
  variant,
  className,
}) => (
  <div
    className={`section-header ${className ? className : ""} ${
      variant ? `-${variant}` : ""
    }`}
  >
    {labelTop && (
      <p
        className="section-header__label-top"
        dangerouslySetInnerHTML={{ __html: labelTop }}
      />
    )}
    {header && (
      <h3
        className={`section-header__header`}
        dangerouslySetInnerHTML={{ __html: header }}
      />
    )}
    {labelBottom && (
      <p
        className="section-header__label-bottom"
        dangerouslySetInnerHTML={{ __html: labelBottom }}
      />
    )}
  </div>
);

export default SectionHeader;
